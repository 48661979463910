import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map, fromJS } from 'immutable'
import moment from 'moment-timezone'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'

import { makeStyles } from 'tss-react/mui'
import { blue, green, grey, orange, blueGrey, purple } from '@mui/material/colors'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Tooltip,
  Typography,
  Box,
  Divider,
  Menu,
  MenuItem
} from '@mui/material'

import MailIcon from '@mui/icons-material/MailOutline'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import CampaignIcon from '@mui/icons-material/Campaign'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import CloseIcon from '@mui/icons-material/Close'

import NrxProgressBar from 'components/nrx_progress_bar/NrxProgressBar'

import { calendarEvents } from 'utils/calendar_events'

import { getLocale } from 'utils/date'

const Transition = React.forwardRef((props, ref) => (
  <Slide
    direction="left"
    ref={ref}
    {...props}
  />
))
Transition.displayName = 'Transition'

const useStyles = makeStyles()(theme => ({
  content: {
    '& .fc-event-past': {
      marginLeft: '2px',
      marginRight: '2px'
    },
    '& .fc-button': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      textTransform: 'uppercase',
      border: 'none',
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        opacity: 0.8
      },
      '&:focus': {
        boxShadow: 'none !important'
      },
      '&:active': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        opacity: 0.8
      }
    },
    '& .fc-day-other': {
      backgroundColor: grey[100]
    },
    '& .fc-day-today': {
      backgroundColor: 'transparent !important',
      outline: '2px solid black',
      outlineOffset: '-2px',
      '& .fc-daygrid-day-number': {
        fontWeight: 'bold'
      }
    }
  },
  fullCalendarEvent: {
    border: 'none !important',
    borderColor: 'transparent !important',
    backgroundColor: 'transparent !important'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  tooltipTitle: {
    whiteSpace: 'pre-line'
  },
  draft: {
    backgroundColor: grey[500]
  },
  reviewPending: {
    backgroundColor: orange[500]
  },
  revisionsNeeded: {
    backgroundColor: purple[500]
  },
  approved: {
    backgroundColor: green[500]
  },
  active: {
    backgroundColor: blue[500]
  },
  closed: {
    backgroundColor: blueGrey[500]
  },
  typeIcon: {
    marginRight: 5,
    fontSize: '20px'
  },
  statusIcon: {
    marginRight: 5,
    fontSize: '20px'
  },
  campaignEvent: {
    borderRadius: 6,
    padding: '2px 5px 2px 5px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  contentEvent: {
    paddingLeft: '5px',
    paddingRight: '5px',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '&:hover': {
      borderRadius: 6,
      backgroundColor: grey[200]
    },
    cursor: 'pointer'
  },
  contentInfoWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    minWidth: 0
  },
  contentStatusCircle: {
    marginRight: '5px',
    width: '14px',
    minWidth: '14px',
    height: '14px',
    borderRadius: '50%'
  },
  eventLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer',
    fontSize: '14px'
  },
  overlay: {
    zIndex: 10,
    position: 'fixed',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.2)'
  },
  progressBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '90%'
  },
  infoContainer: {
    display: 'flex',
    gap: '20px'
  },
  calendarInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  calendarInfoItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  calendarInfoItemColor: {
    borderRadius: '50%',
    width: '14px',
    height: '14px'
  },
  calendarInfoItemTitle: {
    fontSize: '14px',
    fontWeight: 500
  },
  calendarInfoItemLabel: {
    fontSize: '14px',
    fontWeight: 400
  },
  campaignEventProgressLabel: {
    cursor: 'pointer',
    marginLeft: '10px',
    fontSize: '14px'
  },
  campaignEventLabelContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  contentStatusWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  otsIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '14px',
    marginLeft: '3px',
    marginRight: '5px'
  }
}))

const CalendarDialog = ({
  events,
  open,
  hasContentDeskModule,
  calendarDate,
  onClose,
  onContentClick,
  onCampaignClick,
  onNewContentClick,
  onNewCampaignClick,
  fetchContents,
  setCalendarDate,
  requestRunning
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const wrapperRef = useRef(null)
  const [newEventTop, setNewEventTop] = useState(200)
  const [newEventLeft, setNewEventLeft] = useState(200)
  const [newEventOpen, setNewEventOpen] = useState(false)
  const [newEventStartDate, setNewEventStartDate] = useState(new Date())
  const [newEventEndDate, setNewEventEndDate] = useState(new Date())
  const [showOuterTooltip, setShowOuterTooltip] = useState(true)

  const convertedEvents = calendarEvents(events.get('items'))

  useEffect(() => {
    if (open && calendarDate) {
      fetchContents(calendarDate)
    }
  }, [open, calendarDate])

  const handleNewEventClose = () => {
    setNewEventOpen(false)
  }

  const handleNewContentClick = () => {
    onNewContentClick({
      plannedFor: moment(newEventEndDate).subtract(12, 'hours').toDate()
    })
    handleNewEventClose()
  }

  const handleNewCampaignClick = () => {
    onNewCampaignClick({
      startDate: moment(newEventStartDate).add(12, 'hours').toDate(),
      endDate: moment(newEventEndDate).subtract(12, 'hours').toDate()
    })
    handleNewEventClose()
  }

  const handleEventClick = extendedProps => {
    if (extendedProps.eventType === 'content') {
      return onContentClick(fromJS(extendedProps.content))
    }

    return onCampaignClick(extendedProps.campaign)
  }

  const onCalendarNavClick = calendarApi => {
    setCalendarDate(moment(calendarApi.getDate()).format('YYYY-MM-DD'))
  }

  const onDaySelect = info => {
    setNewEventStartDate(info.start)
    setNewEventEndDate(info.end)

    if (info.jsEvent) {
      setNewEventTop(info.jsEvent.clientY)
      setNewEventLeft(info.jsEvent.clientX)
      setNewEventOpen(true)
    }
  }

  const renderCampaign = eventInfo => {
    const campaignSummary = eventInfo.event.extendedProps.campaign.summary

    const campaign = () => (
      <Box
        className={classes.campaignEvent}
        style={{ backgroundColor: eventInfo.event.extendedProps.color }}
      >
        <CampaignIcon className={classes.typeIcon} />
        <Box className={classes.campaignEventLabelContainer}>
          <Typography className={classes.eventLabel}>
            {eventInfo.event.title}
          </Typography>
          <Typography className={classes.campaignEventProgressLabel}>
            {eventInfo.event.extendedProps.campaign.progress}%
          </Typography>
        </Box>
      </Box>
    )

    if (!campaignSummary) {
      return campaign()
    }

    const tooltipTitle = (
      <Box className={classes.tooltipTitle}>
        {campaignSummary}
      </Box>
    )

    return (
      <Tooltip
        title={tooltipTitle}
        placement="top"
        arrow
      >
        {campaign()}
      </Tooltip>
    )
  }

  const renderContent = eventInfo => {
    const statusClass = classes[eventInfo.event.extendedProps.approvalStatus]
    const tooltipTitle = (
      <Box className={classes.tooltipTitle}>
        {`Campaign: ${eventInfo.event.extendedProps.campaignName} \n Content: ${eventInfo.event.title}`}
      </Box>
    )

    const tooltipStatus = (
      <Box className={classes.tooltipTitle}>
        {i18n.get(eventInfo.event.extendedProps.status)} {`(${eventInfo.event.extendedProps.time})`}
      </Box>
    )

    return (
      <Tooltip
        title={tooltipTitle}
        disableHoverListener={!showOuterTooltip}
        placement="top"
        arrow
      >
        <Box className={classes.contentEvent}>
          <Box
            className={classes.contentInfoWrapper}
            onMouseEnter={() => setShowOuterTooltip(true)}
            onMouseLeave={() => setShowOuterTooltip(false)}
          >
            {eventInfo.event.extendedProps.type === 'email' && <MailIcon className={classes.typeIcon} />}
            {eventInfo.event.extendedProps.type === 'linkedin' && <LinkedInIcon className={classes.typeIcon} />}
            {eventInfo.event.extendedProps.type === 'facebook' && <FacebookIcon className={classes.typeIcon} />}
            {eventInfo.event.extendedProps.type === 'ots_press_release' && (
              <Box className={classes.otsIconWrapper}>
                <img
                  src="https://static.pressrelations.de/pics/groot/ccd/naLogo_Condensed.png"
                  width="28"
                  height="14"
                  alt="ots"
                />
              </Box>
            )}
            <Tooltip
              title={tooltipStatus}
              placement="top"
              arrow
            >
              <Box
                className={classes.contentStatusWrapper}
                onMouseEnter={() => setShowOuterTooltip(false)}
                onMouseLeave={() => setShowOuterTooltip(true)}
              >
                {eventInfo.event.extendedProps.status === 'planned' && <TimerOutlinedIcon className={classes.statusIcon} />}
                {eventInfo.event.extendedProps.status === 'scheduled' && <AccessTimeIcon className={classes.statusIcon} />}
                {eventInfo.event.extendedProps.status === 'released' && <CheckCircleOutlineIcon className={classes.statusIcon} />}
                {eventInfo.event.extendedProps.status === 'failed' && <CancelOutlinedIcon className={classes.statusIcon} />}
              </Box>
            </Tooltip>
            <Box className={`${classes.contentStatusCircle} ${statusClass}`} />
            <Typography className={classes.eventLabel}>{eventInfo.event.title}</Typography>
          </Box>
        </Box>
      </Tooltip>

    )
  }

  const renderEventContent = eventInfo => {
    if (eventInfo.event.extendedProps.eventType === 'campaign') {
      return renderCampaign(eventInfo)
    }

    return renderContent(eventInfo)
  }

  const renderInfo = () => (
    <Box className={classes.infoContainer}>
      <Box className={classes.calendarInfo}>
        <Typography className={classes.calendarInfoItemTitle}>{i18n.get('campaign')}:</Typography>
        <Box className={classes.calendarInfoItem}>
          <Box
            className={classes.calendarInfoItemColor}
            style={{ backgroundColor: blue[500] }}
          />
          <Typography className={classes.calendarInfoItemLabel}>{i18n.get('active')}</Typography>
        </Box>
        <Box className={classes.calendarInfoItem}>
          <Box
            className={classes.calendarInfoItemColor}
            style={{ backgroundColor: blueGrey[500] }}
          />
          <Typography className={classes.calendarInfoItemLabel}>{i18n.get('closed')}</Typography>
        </Box>
      </Box>
      <Divider
        orientation="vertical"
        flexItem
      />
      <Box className={classes.calendarInfo}>
        <Typography className={classes.calendarInfoItemTitle}>{i18n.get('content')}:</Typography>
        <Box className={classes.calendarInfoItem}>
          <Box
            className={classes.calendarInfoItemColor}
            style={{ backgroundColor: grey[500] }}
          />
          <Typography className={classes.calendarInfoItemLabel}>{i18n.get('draft')}</Typography>
        </Box>
        <Box className={classes.calendarInfoItem}>
          <Box
            className={classes.calendarInfoItemColor}
            style={{ backgroundColor: purple[500] }}
          />
          <Typography className={classes.calendarInfoItemLabel}>{i18n.get('revisions_needed')}</Typography>
        </Box>
        <Box className={classes.calendarInfoItem}>
          <Box
            className={classes.calendarInfoItemColor}
            style={{ backgroundColor: orange[500] }}
          />
          <Typography className={classes.calendarInfoItemLabel}>{i18n.get('review_pending')}</Typography>
        </Box>
        <Box className={classes.calendarInfoItem}>
          <Box
            className={classes.calendarInfoItemColor}
            style={{ backgroundColor: green[500] }}
          />
          <Typography className={classes.calendarInfoItemLabel}>{i18n.get('approved')}</Typography>
        </Box>
      </Box>
    </Box>
  )

  const calendarConfig = {
    customButtons: {
      prevButton: {
        text: 'prev',
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.prev()
          onCalendarNavClick(calendarApi)
        }
      },
      nextButton: {
        text: 'next',
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.next()
          onCalendarNavClick(calendarApi)
        }
      },
      todayButton: {
        text: i18n.get('today'),
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.today()
          onCalendarNavClick(calendarApi)
        }
      }
    },
    headerToolbar: {
      left: 'title',
      center: '',
      right: 'prevButton,nextButton,todayButton'
    },
    buttonIcons: {
      prevButton: 'chevron-left',
      nextButton: 'chevron-right'
    },
    titleFormat: {
      year: 'numeric',
      month: 'long'
    },
    initialDate: (() => {
      if (convertedEvents.isEmpty()) {
        return moment().format('YYYY-MM-DD')
      }

      return moment(convertedEvents.last().start).format('YYYY-MM-DD')
    })(),
    plugins: [dayGridPlugin, interactionPlugin],
    firstDay: 1,
    dayMaxEventRows: true,
    editable: false,
    selectable: true,
    select: onDaySelect
  }

  const renderActionMenu = () => {
    if (hasContentDeskModule) {
      return (
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={{ top: newEventTop, left: newEventLeft }}
          open={newEventOpen}
          onClose={handleNewEventClose}
        >
          <MenuItem onClick={handleNewContentClick}>{i18n.get('new_content')}</MenuItem>
          <MenuItem onClick={handleNewCampaignClick}>{i18n.get('new_campaign')}</MenuItem>
        </Menu>
      )
    }

    return null
  }

  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => onClose()}
        fullWidth
        fullScreen
        TransitionComponent={Transition}
      >
        <DialogTitle className={classes.dialogTitle}>
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
          {renderInfo()}
        </DialogTitle>
        <DialogContent className={classes.content}>
          {requestRunning && (
            <Box className={classes.overlay}>
              <Box className={classes.progressBar}>
                <NrxProgressBar />
              </Box>
            </Box>
          )}
          <FullCalendar
            ref={wrapperRef}
            locales={allLocales}
            locale={getLocale()}
            events={convertedEvents.toJS()}
            eventContent={renderEventContent}
            eventClick={e => {
              if (hasContentDeskModule) {
                handleEventClick(e.event.extendedProps)
              }
            }}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              meridiem: false
            }}
            eventClassNames={() => [classes.fullCalendarEvent]}
            {...calendarConfig}
          />
        </DialogContent>
      </Dialog>
      {renderActionMenu()}
    </Box>
  )
}

CalendarDialog.propTypes = {
  events: PropTypes.instanceOf(Map).isRequired,
  open: PropTypes.bool.isRequired,
  hasContentDeskModule: PropTypes.bool.isRequired,
  calendarDate: PropTypes.string.isRequired,
  requestRunning: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
  onContentClick: PropTypes.func.isRequired,
  onCampaignClick: PropTypes.func.isRequired,
  onNewContentClick: PropTypes.func.isRequired,
  onNewCampaignClick: PropTypes.func.isRequired,
  fetchContents: PropTypes.func.isRequired,
  setCalendarDate: PropTypes.func.isRequired
}

export default CalendarDialog
