import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

import { makeStyles } from 'tss-react/mui'
import { Alert, AlertTitle, Box } from '@mui/material'
import LockOpenIcon from '@mui/icons-material/LockOpen'

import { IconButton, Button } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  OrderProgressContainer: {
    margin: '20px 40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px'
  },
  alertContainer: {
    border: `1px solid ${theme.palette.info.dark}`,
    width: '100%',
    '& .MuiAlert-action': {
      alignItems: 'center'
    },
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 700
    }
  }
}))

const OrderProgress = ({
  status,
  onUnlockClick
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const device = useDevice()

  if (status === 'completed') {
    return null
  }

  let alertTitle = i18n.get('themax_order_full_version')
  let alertContent = i18n.get('themax_order')

  if (status === 'pending') {
    alertTitle = i18n.get('themax_paid_feature_order_pending_title')
    alertContent = i18n.get('paid_feature_order_pending', { email: 'themax@pressrelations.de' })
  }

  const renderUnlockButton = () => {
    if (device.get('mobile')) {
      return (
        <IconButton
          color="primary"
          onClick={() => onUnlockClick()}
          disabled={status === 'pending'}
          title={i18n.get('unlock')}
        >
          <LockOpenIcon />
        </IconButton>
      )
    }

    return (
      <Button
        color="primary"
        variant="contained"
        className={classes.unlockButton}
        onClick={() => onUnlockClick()}
        disabled={status === 'pending'}
      >
        {i18n.get('unlock')}
      </Button>
    )
  }

  return (
    <div
      className={classes.OrderProgressContainer}
    >
      <Alert
        severity={status === 'pending' ? 'warning' : 'info'}
        className={classes.alertContainer}
        action={(
          <>
            {renderUnlockButton()}
          </>
        )}
      >
        <AlertTitle>{alertTitle}</AlertTitle>
        <Box
          dangerouslySetInnerHTML={{
            __html: alertContent
          }}
        />
      </Alert>
    </div>
  )
}

OrderProgress.propTypes = {
  status: PropTypes.string,

  onUnlockClick: PropTypes.func.isRequired
}

export default OrderProgress
