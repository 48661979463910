import { connect } from 'react-redux'

import { Capabilities } from 'static/constants'

import Actions from 'components/contact_management/actions/Actions'

import { hasCapability, getCmHasTags } from 'selectors'

import { openCalendarDialog } from 'actions/content_desk'
import { openTagsDialog } from 'actions/contact_management'

const mapStateToProps = state => ({
  hasContentDeskModule: !!hasCapability(state, Capabilities.HAS_CONTENT_DESK_MODULE),
  hasTags: getCmHasTags(state)
})

export default connect(
  mapStateToProps,
  {
    openCalendarDialog,
    openTagsDialog
  }
)(Actions)
