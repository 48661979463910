import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'

import { StaggeredList, StaggeredListItem } from 'components/staggered_list'

import { IconButton } from 'containers/themed'
import NotificationsButton from 'containers/notifications/NotificationsButton'
import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()({
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tagsIcon: {
    marginRight: '0.3em'
  }
})

export default function Actions({
  hasContentDeskModule,
  hasTags,
  openCalendarDialog,
  openTagsDialog
}) {
  const i18n = useI18n()
  const { classes } = useStyles()

  const actions = []

  if (hasContentDeskModule) {
    actions.push(
      <IconButton
        size="small"
        title={i18n.get('calendar')}
        color="inherit"
        onClick={() => openCalendarDialog()}
      >
        <CalendarMonthIcon />
      </IconButton>
    )
  }

  if (hasTags) {
    actions.unshift(
      <IconButton
        className={classes.tagsIcon}
        size="small"
        title={i18n.get('manage_tags')}
        color="inherit"
        onClick={() => openTagsDialog()}
      >
        <LocalOfferIcon />
      </IconButton>
    )
  }

  actions.push(<NotificationsButton />)

  return (
    <div className={classes.actionsWrapper}>
      <StaggeredList>
        <div className={classes.actionsWrapper}>
          {actions.map((action, index) => (
            <StaggeredListItem
              key={index}
              delay={index * 50}
            >
              {action}
            </StaggeredListItem>
          ))}
        </div>
      </StaggeredList>
    </div>
  )
}

Actions.propTypes = {
  hasContentDeskModule: PropTypes.bool.isRequired,
  hasTags: PropTypes.bool.isRequired,
  openCalendarDialog: PropTypes.func.isRequired,
  openTagsDialog: PropTypes.func.isRequired
}
